import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Seo';

function EntregaPage() {
  return (
    <section className="px-2 py-6 md:px-16">

      <SEO
        keywords={[`about`, `sobre`, `trocas`, `dovolucoes`, `devoluções`]}
        title="Trocas e Devoluções"
      />

    <div className="text-base text-gray-700">
      <div className="px-6 md:px-20 md:mx-20">

          <h4 className="pt-8 text-xl uppercase font-bold">Sobre a Entrega</h4>
          <h4 className="pt-8 uppercase font-bold">Prazos e locais de entrega</h4>
          <p className="py-2">Uma estimativa do prazo de entrega é informada a você na página do produto (Calcule o frete e o prazo de entrega estimados para sua região), considerando apenas uma unidade a ser adquirida.</p>
          <p className="py-2">No carrinho, para cada item incluído no pedido, é informado novamente o prazo, que pode ter sofrido variações em função de uma maior quantidade de unidades do produto (maior peso e volume na entrega). Para pedidos com mais de um item, pode haver
            ainda prazos de entrega diferentes, conforme a disponibilidade em nosso estoque. Neste caso, a entrega será feita parcialmente.</p>
          <p className="py-2">Em qualquer circunstância, o prazo começa a valer somente após a aprovação de seu pedido.</p>
          <p className="py-2">O frete de suas compras varia de acordo com a localidade, peso e o volume dos produtos. Para calcular o valor de frete a ser pago, basta escolher o produto, clicar em Comprar e digitar o CEP do local de entrega. Caso seu pedido tenha produtos em
            promoção de frete grátis, o valor calculado do frete será apenas para os produtos fora da promoção.</p>
          <p className="py-2">Não efetuamos entregas aos domingos, nem em feriados.</p>
          <p className="py-2">Não realizamos entrega em Caixa Postal.</p>
          <p className="py-2">É necessário que haja uma pessoa autorizada no local para receber o item, pois é preciso checar, no ato da entrega, se a mercadoria está conforme o solicitado. Se o produto estiver em perfeitas condições, assine o Comprovante de Recebimento e o
            Termo de Conformidade. É importante que ambos documentos sejam assinados. Se o produto apresentar alguma divergência, recuse a entrega e entre em contato imediatamente com o SAC. Em caso de ausência, os Correios realizam mais duas (2) tentativas
            de entrega e se, mesmo assim, não conseguir entregar, a mercadoria retorna a Loja Física da sostrass.</p>
          <p className="py-2">Ao receber o produto, verifique seu perfeito estado e, só então, assine a nota fiscal.</p>
          <h4 className="pt-8 uppercase font-bold">Disponibilidade dos produtos</h4>
          <p className="py-2">A disponibilidade dos produtos depende da relação entre a quantidade de um item no estoque, seu volume de venda e o tempo de reposição do fornecedor. Durante sua navegação no site, você visualiza a disponibilidade do produto junto ao botão
            Comprar.</p>
          <h4 className="pt-8 uppercase font-bold">Pré-venda</h4>
          <p className="py-2">A <a href="http://www.sostrass.com.br">www.sostrass.com.br</a> está sempre em busca de novidades em produtos para você. Periodicamente, você pode encontrar no site
            produtos em pré-venda, ou seja, lançamentos que ainda não chegaram ao mercado, mas que você pode comprar antecipadamente. A entrega destes produtos será realizada a partir da data do lançamento.</p>
          <p className="py-2">Todas as informações são válidas somente para compras realizadas pelo site www.sostrass.com.br e TeleVendas, não valendo necessariamente para a Loja sostrass.</p>
          <h4 className="pt-8 uppercase font-bold">Acompanhe sua Entrega</h4>
          <p className="py-2">Para acompanhar sua entrega, entre com seu login na página <b><a href="http://checkout.sostrass.com.br/login">Meus Pedidos.</a></b></p>
          <p className="py-2">Na página de entrada há um resumo de sua compra. Para verificar todas as informações, basta clicar no número do pedido.</p>
          <h4 className="pt-8 uppercase font-bold">Posso alterar o endereço de entrega?</h4>
          <p className="py-2">O Endereço de entrega pode ser alterado na finalização do pedido. Quando o pedido passa para o processo de picking-packing não é possível a mudança do endereço de entrega para outra faixa de CEP em virtude da variação de frete e alíquotas de
            impostos que já foram recolhidas, como por exemplo, o ICMS (Imposto sobre Circulação de Mercadorias e Serviços).</p>
          <h4 className="pt-8 uppercase font-bold">Como calcular o prazo de entrega?</h4>
          <p className="py-2">Para calcular o prazo de entrega para seu endereço, digite o CEP no campo Calcule o Frete.</p>
          <p className="py-2">Após digitar o CEP, será possível visualizar o prazo e todos os tipos de entrega disponíveis.</p>
          <p className="py-2">Importante: O prazo de entrega tem sua contagem iniciada a partir da aprovação do pagamento junto à instituição financeira.</p>
          <p className="py-2">Regiões atendidas pela<a href="http://www.sostrass.com.br"> www.sostrass.com.br</a></p>
          <p className="py-2">A <a href="http://www.sostrass.com.br">www.sostrass.com.br</a> faz entregas em todo o território nacional através dos Correios.</p>
          <h4 className="pt-8 uppercase font-bold">Atraso na Entrega</h4>
          <p className="py-2">Uma entrega pode sofrer atraso por inúmeros fatores, como demora na liberação do pedido por parte da administradora do cartão de crédito, inconsistência de dados fornecidos pelo cliente, ausência no local de entrega, recusa no recebimento por
            terceiros, além de fenômenos naturais.</p>
          <p className="py-2">Através de nossa Central de Atendimento será possível obter todas as informações referentes a um possível atraso em sua entrega.</p>
          <h4 className="pt-8 uppercase font-bold">Entregas parciais de produtos</h4>
          <p className="py-2">Quando um pedido contém mais de um produto, pode ocorrer uma situação excepcional em que as entregas ocorrerão separadamente.</p>
          <p className="py-2">Esta situação está relacionada ao tamanho do produto e sua disponibilidade em nosso estoque.</p>
          <h4 className="pt-8 uppercase font-bold">Entrega com prazo diferenciado</h4>
          <p className="py-2">A data específica de disponibilidade de um produto será informada em sua página de detalhes no site.</p>
          <p className="py-2">No carrinho de compras, quando o CEP de entrega for digitado, serão adicionadas a data de disponibilidade e a quantidade de dias úteis para a entrega.</p>
          <p className="py-2">O prazo final para entrega será composto pela soma da data de disponibilidade com a quantidade de dias úteis para entrega.</p>
          <h4 className="pt-8 uppercase font-bold">Como os produtos são embalados para o transporte?</h4>
          <p className="py-2">Todos os produtos são armazenados em embalagens especiais, a fim de garantir a sua qualidade e evitar a avaria.</p>
        </div>

    </div>

    </section>
  );
}

export default EntregaPage;
